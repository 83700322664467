
export enum RoutesModulesExternalsEnum {
    // PARAMS
    ID = ':id',
    STUDIES_ID = ':studieId',
    STUDIES_NAME = ':studieName',
    DELIVERABLE_ID = ':deliverableId',
    DELIVERABLE_NAME = ':deliverableName',

    // BASE PATHS
    CONSULTANCY = 'consultores',

    // PAGES
    STUDIES = 'meus-estudos',

    // STUDIES
    MY_STUDIES = `${STUDIES}`,
    DELIVERED_STUDIES_ID = `${STUDIES_NAME}/${STUDIES_ID}`,
    DELIVERED_STUDIES_VOLUME = `${DELIVERED_STUDIES_ID}/${DELIVERABLE_NAME}/${DELIVERABLE_ID}`,
}

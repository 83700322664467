import keycloakPreProdConfig from './keycloak-pre-prod.config'

export const environment = {
  production: false,
  keycloak: keycloakPreProdConfig,
  apiUrl: 'https://api.preprod.smartlicensingweb.com',
  workspace: '0c7e4d17-cb83-41cc-ae4b-2dd0711920e3',
  dashStudy: '3b84b992-6c20-4b44-99a7-38b48d7fa1ae',
  dashSuppliers: 'eae41a8c-337a-4ad5-b4c2-c69c9b3fef1c',
  dashLicensing: '23145c50-2aa7-4335-a2c4-c3e998c2b5fb',
  dashManager: '62f57231-9326-4478-8a6f-e7224c677a7e',
  dashRipla: 'af7fd51c-8ee6-413b-bb38-5bf69cca614a',
  dashNegocios: 'b1a4753c-5904-4950-ab04-16cc8084d8ae',
  uploadFileSize: 1024,
  activeSuppliers: false
}

<div class="app-generic-table">
    <p-table
        [value]="dataList"
        scrollHeight="400px"
        [resizableColumns]="true"
        styleClass="p-datatable-gridlines"
        [(selection)]="selectedDataValues"
        (onRowSelect)="onChangeSelect()"
        (onRowUnselect)="onChangeSelect()"
        [tableStyle]="{'min-width': '50rem'}"
        [scrollable]="true">
            <ng-template pTemplate="header">
                <tr>
                    @if (dataConfiguration.isCheckbox) {
                        <th class="th__checkbox">
                            <div class="content__display__th" style="white-space: normal;">
                                <p-tableHeaderCheckbox (click)="onChangeSelect()" />
                                <span class="custom__border__right"></span>
                            </div>
                        </th>
                    }

                    @if (dataConfiguration.isResizeColumn) {
                        <th pResizableColumn *ngFor="let header of dataHeaders">
                            <div class="content__display__th">
                                {{ header.label }}
                                <ng-container *ngTemplateOutlet="header.templateName"></ng-container>
                                <span class="custom__border__right"></span>
                            </div>
                        </th>
                    }

                    @if (!dataConfiguration.isResizeColumn) {
                        <th *ngFor="let header of dataHeaders">
                           <div class="content__display__th">
                                {{ header.label }}
                                <ng-content [select]="header.templateName"></ng-content>
                                <span class="custom__border__right"></span>
                           </div>
                        </th>
                    }
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
                <tr [class.hover__actived]="dataConfiguration.isClickRowEvent" [ngClass]="{'selected__row': isSelected(rowData)}">
                    @if (dataConfiguration.isCheckbox) {
                        <td class="overflow__text">
                            <div class="column__checkbox">
                                <p-tableCheckbox [value]="rowData" />
                            </div>
                        </td>
                    }
                    @for (item of dataHeaders; track $index) {
                        <td class="overflow__text"
                            [pTooltip]="tooltipContent"
                            [tooltipDisabled]="!resolveField(rowData, item.field, item.subField) ||
                            (item.disableTooltip !== undefined && item.disableTooltip)"
                            [tooltipStyleClass]="'overlay__tooltip_table'"
                            tooltipPosition="right"
                            (click)="onRowClick(rowData, item.isLink)"
                            (mouseenter)="onRowHover(rowData)"
                            [class.cursor__pointer]="dataConfiguration.isClickRowEvent"
                            [class.disabled__row]="dataConfiguration.isDisableRow ? !rowData[dataConfiguration.isDisableRow.field] : false">
                            @if (item.isBadge) {
                                <app-badge [badgeText]="resolveField(rowData, item.field, item.subField)" class="status-badge"> </app-badge>
                            }

                            @if (isDateBehindSchedule(rowData, item.field)) {
                                <span (click)="onColClick(rowData, $event)" [class.link]="item.isLink" class="date-behind-schedule">
                                    {{ item.field === 'delete' ? '' : (resolveField(rowData, item.field, item.subField) || '--') }}
                                </span>
                            }

                            @if (!item.isBadge && !isDateBehindSchedule(rowData, item.field) && item.isColor) {
                                <span (click)="onColClick(rowData, $event)" [class.link]="item.isLink" [ngClass]="getColorForColumn(rowData.color, item.field)">
                                    {{ item.field === 'delete' ? '' : (resolveField(rowData, item.field, item.subField) || '--') }}
                                </span>
                            }

                            <!-- FIRST COLUMN EDITABLE -->
                            @if (!item.isBadge && !isDateBehindSchedule(rowData, item.field) && !item.isColor) {
                                @if (dataHeaders[0].isEditable && item.field === dataHeaders[0].field &&
                                    editableConfiguration?.editable && editableConfiguration?.id === rowData[editableConfiguration.targetField])
                                {
                                    <input pInputText type="text"
                                        [id]="'input'+ rowData[editableConfiguration.targetField]"
                                        class="input_editable"
                                        (focusout)="onInputFocusOut(rowData)"
                                        (keydown)="onChangeEditRow(rowData, $event)"
                                        [(ngModel)]="rowData[item.field]"/>
                                } @else {
                                    <a *ngIf="item.isLink"
                                        [href]="rowData.linkToAccess" 
                                        (click)="onColClick(rowData, $event)"
                                        [class.link]="item.isLink"
                                        [class.link__icon]="item.templateIcon && !editableConfiguration?.editable">
                                        {{ item.field === 'delete' ? '' : (resolveField(rowData, item.field, item.subField) || '--') }}
                                    </a>

                                    <span 
                                        *ngIf="!item.isLink"
                                        (click)="onColClick(rowData, $event)"
                                        [class.link]="item.isLink"
                                        [class.link__icon]="item.templateIcon && !editableConfiguration?.editable">
                                        {{ item.field === 'delete' ? '' : (resolveField(rowData, item.field, item.subField) || '--') }}
                                    </span>

                                }

                            }

                            <!-- HABILITA POSSIBILIDADE DE ICONS (EDIT / DELETE / ...) -->

                            @if (item.templateIcon && !editableConfiguration?.editable) {
                                <div class="container__icons">
                                    <ng-container *ngTemplateOutlet="item.templateIcon"></ng-container>
                                </div>
                            }
                        </td>
                        <ng-template #tooltipContent>
                            <div *ngFor="let line of formatToMultiline(resolveField(rowData, item.field, item.subField), item.isCustomTooltipDescription)"
                                class="flex align-items-center">
                                {{ line }}
                            </div>
                        </ng-template>
                    }
                </tr>
            </ng-template>
    </p-table>

    <div class="no__data__content" *ngIf="dataList.length === 0 && !isLoading">
        <svg width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg" class="ant-empty-img-simple" id="no_data_table64">
            <g transform="translate(0 1)" fill="none" fill-rule="evenodd"><ellipse cx="32" cy="33" rx="32" ry="7" class="ant-empty-img-simple-ellipse">
            </ellipse><g fill-rule="nonzero" class="ant-empty-img-simple-g">
            <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z">
            </path><path d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z" class="ant-empty-img-simple-path">
            </path></g></g>
        </svg>
        <p> Não há dados </p>
    </div>
</div>

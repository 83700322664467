<div class="app-generic-edit-table">
    <p-table
        [value]="dataList" 
        [resizableColumns]="true" 
        styleClass="p-datatable-gridlines"
        [tableStyle]="{'min-width': '50rem'}">
        <ng-template pTemplate="header">
            <tr>
                @if (dataConfiguration.isResizeColumn) {
                    @for (header of dataHeaders; track $index) {
                        <th pResizableColumn [id]="header.field+'-th'">
                            <div class="content__display__th">
                                {{ header.label }}
                                <ng-container *ngTemplateOutlet="header.templateName"></ng-container>
                                <span class="custom__border__right"></span>
                            </div>
                        </th>
                    }
                }

                @if (!dataConfiguration.isResizeColumn) {
                    @for (header of dataHeaders; track $index) {
                        <th [id]="header.field+'-resize-th'">
                           <div class="content__display__th">
                                {{ header.label }}
                                <ng-content [select]="header.templateName"></ng-content>
                                <span class="custom__border__right"></span>
                           </div>
                        </th>
                    }
                }

                @if (dataConfiguration.isDeleteIcon || dataConfiguration.isEditIcon || verifyIfHasIconDelete()) {
                    <th id="lastcolumn-th"></th>
                }
            </tr>
        </ng-template>
        
        <ng-template pTemplate="body" let-rowData>
            <span *ngIf="storeRowData(rowData)"></span>
            <tr>
                @for (dataHeader of dataHeaders; track $index) {
                    @if (rowData._disableInputCell && dataHeader.isLink) {
                        <td>
                            <div class="input__size">
                                <div [class.link]="dataHeader.isLink" (click)="onClickLink(rowData)"> {{ rowData[dataHeader.field].label }}
                                </div>
                            </div>
                        </td>
                    }
                    @else {

                        <td [pEditableColumn]="rowData[dataHeader.field]" [pEditableColumnField]="rowData[dataHeader.field]"
                        [class.badge]="dataHeader.typeInput === 'badge'"
                        [class.disable__column]="(rowData.id ===0 && dataHeader.insertRowConfig?.isEditable ? true : false) || !dataHeader.isEditable" >
                            <!-- INPUT TEXT -->
                            @if (dataHeader.typeInput === 'text') {
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input
                                            pInputText 
                                            type="text" 
                                            class="input__size"
                                            [(ngModel)]="rowData[dataHeader.field]" 
                                            (focusout)="onCreateOrEditRowInputText($event, rowData, dataHeader.nextFocusInput)"
                                            (keydown)="onKeyDownInputText($event, rowData, dataHeader.nextFocusInput)"
                                            [class.text__placeholder]="rowData._isValidInputText !== false"
                                            [class.invalid__filed]="rowData._isValidInputText === false"
                                            (ngModelChange)="onModelChangeInputText($event, rowData, dataHeader.isRequired, dataHeader.inputTextConfig?.maxLegth)"/>
                                    </ng-template>
            
                                    <ng-template pTemplate="output">
                                        <div 
                                            [id]="dataHeader.field+'-'+rowData.id"
                                            class="input__size" 
                                            [class.ft__color__gray]="rowData.id === 0"
                                            [class.text__placeholder]="rowData._isValidInputText !== false"
                                            [class.invalid__filed]="rowData._isValidInputText === false">
                                                @if (!rowData[dataHeader.field] && rowData.id === 0) {
                                                    <span> {{ dataHeader.insertRowConfig?.placeholder }} </span>
                                                } 
                                                @if (!rowData[dataHeader.field] && rowData.id !== 0) {
                                                    <span> {{ dataHeader.insertRowConfig?.placeholder }} </span>
                                                } 
                                                @if (rowData[dataHeader.field]) {
                                                    {{ rowData[dataHeader.field] }}
                                                }
                                        </div>
                                    </ng-template>
                                </p-cellEditor>
                            }
    
                            <!-- DROPDOWN -->
                            @if (dataHeader.typeInput === 'select') {
                                <p-cellEditor [id]="dataHeader.field+'-'+rowData.id">
                                    <ng-template pTemplate="input">
                                            <nz-select
                                                id="idSelectGenericEditableTable"
                                                [(ngModel)]="rowData[dataHeader.field]"
                                                (nzScrollToBottom)="onloadMoreSelect(dataHeader.field)"
                                                [nzPlaceHolder]="dataHeader.dropdownConfig?.dropdownPlaceHolder || ''"
                                                nzAllowClear
                                                nzShowSearch
                                                [nzStatus]="dataHeader.isRequired && rowData._isValidSelect === false ? 'error' : ''"
                                                [nzDropdownRender]="renderTemplate"
                                                [nzAllowClear]="dataHeader.isRequired"
                                                [nzNotFoundContent]="renderTemplateNotFound"
                                                [nzSuffixIcon]="''"
                                                [nzDropdownStyle]="{ 'border-radius': '8px' }"
                                                [nzDisabled]="rowData.id === 0 && dataHeader.dropdownConfig?.dropdownDisabled || !dataHeader.isEditable || rowData._disableEdit"
                                                (nzOnSearch)="resizeSelectOptionsOverlay($event)"
                                                (ngModelChange)="onModelChangeInputSelect($event, rowData, dataHeader.isRequired, dataHeader.nextFocusInput)">
                                                <nz-option 
                                                *ngFor="let o of (dataHeader.dropdownConfig?.dropdownOptions?.length ? dataHeader.dropdownConfig?.dropdownOptions : rowData.optionsList || [])"
                                           
                                                [nzValue]="o" [nzLabel]="o.label"></nz-option>
                                            </nz-select>
                                            <ng-template #renderTemplate>
                                                <nz-spin *ngIf="isLoading"></nz-spin>
                                            </ng-template>

                                            <ng-template #renderTemplateNotFound>
                                                <div class="content__not__found">
                                                    
                                                    <div class="text" *ngIf="dataHeader.dropdownConfig?.dropdownNoContentMessage">
                                                        {{ dataHeader.dropdownConfig?.dropdownNoContentMessage }}
                                                    </div>
        
                                                    <div class="icon" *ngIf="!dataHeader.dropdownConfig?.dropdownNoContentMessage">
                                                        <img src="assets/icons/archive_gray-icon24.svg" alt="Icon">
                                                        <span>Não a dados</span>
                                                    </div>
                                                </div>
                                            </ng-template>
                                    </ng-template>
            
                                    <ng-template pTemplate="output">
                                        <div class="input__size"
                                            [class.ft__color__gray]="rowData.id === 0"
                                            [class.text__placeholder]="rowData._isValidSelect !== false"
                                            [class.invalid__filed]="rowData._isValidSelect === false && dataHeader.isRequired ">
                                            
                                            @if (!rowData[dataHeader.field]) {
                                                
                                                @if(dataHeader.dropdownConfig?.dropdownIconNewRow) {
                                                    <div class="iconSelectNewRow">
                                                       <img [src]="'assets/icons/' + (dataHeader.dropdownConfig?.dropdownIconNewRow) + '.svg'" alt="Icon">
                                                    </div>
                                                } @else {
                                                    <span> {{ dataHeader.dropdownConfig?.dropdownPlaceHolder }} </span>
                                                }
                                            } @else {
                                                <div> {{ rowData[dataHeader.field].label }}</div>
                                            }
                                        </div>
                                    </ng-template>
                                </p-cellEditor>
                            }
    
                            @if (dataHeader.typeInput === 'calendar') {
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <div class="calendar_input_size">
                                            <p-calendar 
                                                [id]="dataHeader.field+'-'+rowData.id"
                                                [(ngModel)]="rowData[dataHeader.field]" 
                                                [minDate]="dataHeader.calendarConfig?.calendarMinDate"
                                                [iconDisplay]="'input'" 
                                                [showIcon]="true"
                                                [appendTo]="'body'"
                                                [disabled]="!dataHeader.isEditable" 
                                                [placeholder]="dataHeader.calendarConfig?.calendarPlaceHolder || ''"
                                                (ngModelChange)="onModelChangeInputCalendar($event, rowData, dataHeader.isRequired)">
                                            </p-calendar>
                                        </div>
                                    </ng-template> 
                                    <ng-template pTemplate="output">
                                        <div class="input__size"
                                            [class.ft__color__gray]="rowData.id === 0"
                                            [class.text__placeholder]="rowData._isValidSelect !== false"
                                            [class.invalid__filed]="rowData._isValidSelect === false && dataHeader.isRequired ">
                                            
                                            @if (!rowData[dataHeader.field]) {
                                                
                                                @if(dataHeader.calendarConfig?.calendarIconNewRow) {
                                                    <div class="iconSelectNewRow">
                                                       <img [src]="'assets/icons/' + (dataHeader.calendarConfig?.calendarIconNewRow) + '.svg'" alt="Icon">
                                                    </div>
                                                } @else {
                                                    <span> {{ dataHeader.calendarConfig?.calendarPlaceHolder }} </span>
                                                }
                                            } @else {
                                                {{ rowData[dataHeader.field] | date: 'dd/MM/yyyy'}}
                                            }
                                            
                                        </div>
                                    </ng-template>
                                </p-cellEditor>
                            }
    
                            @if (dataHeader.typeInput === 'badge') {
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        @if (rowData.id === 0) {
                                            <span> - </span>
                                        } 
                                        @else if (rowData[dataHeader.field]) {
                                            <app-badge [badgeText]="rowData[dataHeader.field]" class="status-badge"> </app-badge>
                                        }
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        @if (rowData.id === 0) {
                                            <span> - </span>
                                        } 
                                        @else if (rowData[dataHeader.field]) {
                                            <app-badge [badgeText]="rowData[dataHeader.field]" class="status-badge"> </app-badge>
                                        }
                                    </ng-template>
                                </p-cellEditor>
                            }
                        </td>
                    }
                    
                }

                @if (dataConfiguration.isDeleteIcon || dataConfiguration.isEditIcon || rowData._iconDelete) {
                    <td (click)="deleteRowData(rowData)">
                        @if (rowData.id !== 0) {
                            <div class="td__edit__delete__icons">
                                <img src="/assets/icons/icon_trash_green12.svg" *ngIf="dataConfiguration.isDeleteIcon || rowData._iconDelete" alt="excluir"/>
                                <img class="ml-8" src="/assets/icons/edit_green-icon24.svg" *ngIf="dataConfiguration.isEditIcon" alt="editar"/>
                            </div>
                        }
                    </td>
                }

            </tr>
        </ng-template>
    </p-table>
   
</div>

import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular'
import { UserService } from 'src/app/shared/services/user.service'
import { AuthService } from "../service/auth.service";
import { RoutesEnum } from "../../../shared/enum/routes.enum";
import { UtilService } from "../../../shared/util.service";
import { AlertMessageService } from 'src/app/shared/services/alert-message.service';
import { RoutesModulesExternalsEnum } from 'src/app/shared/enum/routes-modules-externals';
import {Injectable} from '@angular/core'
import {type ActivatedRouteSnapshot, Router, type RouterStateSnapshot} from '@angular/router'
import {environment} from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard extends KeycloakAuthGuard {

  activeSuppliers: boolean = environment.activeSuppliers;

  constructor(
    protected override readonly router: Router,
    protected readonly keycloak: KeycloakService,
    protected readonly userService: UserService,
    protected readonly auth: AuthService,
    protected readonly util: UtilService,
    private alertMessageService: AlertMessageService,
  ) {
    super(router, keycloak)
  }

  public async isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<any> {
    if (!this.authenticated) {
      sessionStorage.clear();
      await this.keycloak.login({
        redirectUri: window.location.origin + state.url
      })
      sessionStorage.clear();
    }
    let isLogged = false;

    await this.auth.isLoggedIn().then(value => {
      isLogged = value;
    })
    if (isLogged) {
      this.auth.getRoles();
      this.auth.getLoggedUser();
      await this.auth.getApiUser();
      await this.auth.getConfigRole();
    } else {
      sessionStorage.clear()
      this.auth.login()
      return false;
    }

    if (!await this.auth.isActiveItem(route.url)) {
      this.alertMessageService.send("Item inativo ou deletado", 'error');
      if(this.router.lastSuccessfulNavigation){
        const rota = this.router.lastSuccessfulNavigation.finalUrl ? this.router.lastSuccessfulNavigation.finalUrl.toString() : "";
        this.router.parseUrl(rota)
        return this.router.createUrlTree([rota]);
      } else {
        return false;
      }
    }

    if (state.url.includes(RoutesEnum.SMART_DOCS) && !this.util.getAuthorization("visible", "CADEIAVSTR")) {
      await this.router.navigate([RoutesEnum.TASKS]);
      this.alertMessageService.send(`O usuário não tem autorização para acessar a tela de SMART DOCS.`, 'error');
      return false;
  }

    if (this.checkUserConfiguration(state)) {
      await this.router.navigate([RoutesEnum.TASKS]);
      this.alertMessageService.send(`O usuário não tem autorização para acessar a tela de Configuração de usuários.`, 'error');
      return false;
    }

    if(this.checkActiveSuppliers(state)){
      this.alertMessageService.send('Modulo Fornecedores não está ativo', 'error', true)
      return false;
    }


    if (this.roles.includes('ROLE_FORNECEDOR_RESPONSAVEL') || this.roles.includes('ROLE_FORNECEDOR_EQUIPE')) {
      await this.router.navigate([`${RoutesModulesExternalsEnum.MY_STUDIES}`]);
      this.alertMessageService.send(`O usuário não tem autorização para acessar a tela`, 'error', true);
      return false;
    }

    return true;
  }

  private checkActiveSuppliers(state) {
    return !this.activeSuppliers && (
      state.url.includes('configuracoes-estudo') ||
      state.url.includes('gestao-estudo'))
  }


  private checkUserConfiguration(state: RouterStateSnapshot) {
    return state.url.includes(RoutesEnum.CONFIGURATION) &&
      !this.util.getAuthorization('visible', 'USERCONFIGURATION') &&
      !this.util.getAuthorization("visible", "ROLE_FORNECEDOR_RESPONSAVEL") &&
      !this.util.getAuthorization("visible", "ROLE_FORNECEDOR_EQUIPE")
  }
}

import { Component, Input, type OnInit, inject } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import {Observable, ObservableInput, Subject, catchError, map, take, takeUntil, throwError, delay} from 'rxjs';
import { UtilService } from '../../util.service';
import { DeletionService } from '../../services/deletion.service';
import { HttpEventType } from '@angular/common/http';

@Component({
  selector: 'app-loading-modal',
  templateUrl: './loading-modal.component.html',
  styleUrls: ['./loading-modal.component.scss']
})
export class LoadingComponent implements OnInit {
  readonly #modal = inject(NzModalRef);
  @Input() modalTitle: string;
  @Input() loadingCalls: any[];
  @Input() savingMessage: string;
  @Input() savedEntityIdPath: string;
  @Input() initialCallsAmount?: number = 1;
  @Input() requiredValuePath?: string;
  currentLoadingLabel: string;
  entityId: any;
  callsPromisified: any[] = [];
  lastCall: any;
  requiredValue: string;
  uploadStart: any;
  uploadProgress = {};
  uploadEnd = {};
  increment = {};
  uploadError = false;
  errorMesage: string = "";

  private fileIds: { entity: string, value: string }[] = [];
  private cancelUpload$ = new Subject<void>();

  constructor(
    private readonly utilService: UtilService,
    private readonly deletionService: DeletionService
  ){}

  ngOnInit(): void {
    this.makeApiCalls();
  }

  async makeApiCalls() {
    let loadingCalls = this.loadingCalls.slice(0, this.initialCallsAmount);

    for (let loadingCall of loadingCalls) {
      if (loadingCall?.call && loadingCall.entity != "ATTACHMENT") {
        loadingCall.state = 'in_progress';
        this.currentLoadingLabel = "Realizando chamada inicial..."
        await new Promise<void>((resolve, reject) => (loadingCall.call() as Observable<any>).pipe(
          take(1),
          takeUntil(this.cancelUpload$),
          delay(1000))
          .subscribe({
            next: savedEntity => {
              this.currentLoadingLabel = "";
              try {
                if (this.savedEntityIdPath) {
                  this.entityId = this.utilService.getNestedObjectByString(savedEntity, this.savedEntityIdPath);
                  if (this.requiredValuePath) {
                    this.requiredValue = this.utilService.getNestedObjectByString(savedEntity, this.requiredValuePath);
                  }
                }
              } catch (e) {
                this.currentLoadingLabel = "";
                reject(`ID not found with ${this.savedEntityIdPath} path`)
                this.closeModal(false);
              }

              resolve();
            },
            error: error => {
              reject(error);
              if(error !== undefined && error.errors !== undefined && error.errors.details){
                this.errorMesage = this.errorMesage ? this.errorMesage : error.errors.details;
              }

              this.closeModal(false);
            }
          }
        ))

        loadingCall.state = 'finished';
      }
    }

    this.uploadStart = Date.now();
    await this.callNecessaryRequests(this.loadingCalls.slice(this.initialCallsAmount));
    this.makeFinalCallOrClose();
  }

  async callNecessaryRequests(uploadsCalls: any[]) {
    try {
      await Promise.all(uploadsCalls.map(call => {
        call.state = 'in_progress';

        if (call.lastCall) {
          this.lastCall = call;
          return true;
        }

        const callPromisified = this.promisifyCall(call);

        this.callsPromisified.push(callPromisified);
        return callPromisified;
      }))
    } catch (e: any) {
      if(e.error.errors.details){
        console.log(e.error, this.errorMesage)
        this.errorMesage = this.errorMesage ? this.errorMesage :
          e.error.errors.details.replace('415 UNSUPPORTED_MEDIA_TYPE', '').replace('"', '');
      }
      this.uploadError = true;
      this.closeModal(false);
    }
  }

  async makeFinalCallOrClose() {
    if (this.lastCall && this.uploadError === false) {
      this.currentLoadingLabel = this.lastCall.label;
      await this.lastCall.call(this.entityId).pipe(take(1)).subscribe({
        next: (savedEntity) => {
          if (this.requiredValuePath && !this.requiredValue) {
            this.requiredValue = this.utilService.getNestedObjectByString(savedEntity, this.requiredValuePath);
          }
          this.closeModal();
        },
        error: (error) => {
          if (error.error && error.error.errors) {
            this.errorMesage = this.errorMesage ? this.errorMesage : error.error.errors.details;
          } else {
            this.errorMesage = this.errorMesage ? this.errorMesage : error.message;
          }
          this.closeModal(false);
        }
      });
    } else {
      this.closeModal();
    }
  }

  private async promisifyCall(call: any): Promise<void> {
    if (call.entity == "ATTACHMENT") {
      return await this.callingAttachmentUpload(call);
    } else {
      this.setCurrentLoadingLabel(false, true);
      return new Promise<void>((resolve, reject) => (call.call(this.entityId) as Observable<any>).pipe(take(1))
        .subscribe({
          next: () => {
            call.state = 'finished';
            resolve()
          },
          error: () => {
            reject();
          }
        })
      )
    }
  }

  private setCurrentLoadingLabel(isAttach: boolean, isNormalReq: boolean) {
    if (isAttach) {
      if (!this.currentLoadingLabel?.toLowerCase().includes("enviando anexos")) {
        this.currentLoadingLabel = this.currentLoadingLabel?.length > 0 ?
          "Enviando anexos e " + this.currentLoadingLabel.toLowerCase() :
          "Enviando anexos..."
      }
    }

    if (isNormalReq) {
      if (!this.currentLoadingLabel?.toLowerCase().includes("realizando chamadas necessárias")) {
        this.currentLoadingLabel = this.currentLoadingLabel?.length > 0 ?
          "Realizando chamadas necessárias e " + this.currentLoadingLabel.toLowerCase() :
          "Realizando chamadas necessárias..."
      }
    }
  }

  private async callingAttachmentUpload(call: any): Promise<void> {
    this.setCurrentLoadingLabel(true, false)
    return await new Promise<void>((resolve, reject) => (call.call(this.entityId) as Observable<any>).pipe(
      takeUntil(this.cancelUpload$),
      map(async event => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            if (event.total) {
              this.calculateProgressBasedOnEvent(event, call);

              return this.uploadProgress[call.id];
            }

            return 0;
          case HttpEventType.Response:
            if (event.id) {
              this.fileIds.push(event.id);
            }
            this.uploadProgress[call.id] = 100;
            resolve();
            return 100;
          default:
            return 0;
        }
      }),
      catchError((error, _): ObservableInput<any> => {
        if (call.attach && (call.attach.idMandatoryDoc == 2 || call.attach.idMandatoryDoc == 5)) {
          this.errorMesage = "Arquivo inválido. Os arquivos devem estar compactados em um único arquivo(.ZIP) e o pacote deve conter arquivos com as seguintes extensões: shp, shx, dbf e prj";
        }
        reject(error);
        this.uploadError = true;
        return throwError(() => error);
      })
      ).subscribe({
        next: () => {
          call.progress = this.uploadProgress[call.id];
          if (call.progress == 100) {
            call.state = 'finished';
          }
        },
        error: error => {
          this.uploadError = true;
          reject(error);
        }
      })
    )
  }

  public cancelUploads(): void {
    this.fileIds.forEach(fileId => {
      this.deletionService.deleteByEntityNameAndId(fileId.entity, fileId.value).subscribe();
    });
    this.fileIds = [];
    this.cancelUpload$.next();
    this.cancelUpload$.complete();
    this.cancelUpload$ = new Subject<void>();
    this.closeModal(true, true, true);
  }

  private calculateProgressBasedOnEvent(event: any, call: any) {
    const load = Math.round(45 * (event.loaded / event.total));
    this.uploadProgress[call.id] = load;

    if (load == 45 && !this.uploadEnd[call.id]) {
      this.uploadEnd[call.id] = Date.now();
      const uploadTime = this.uploadEnd[call.id] - this.uploadStart;

      const processingTime = uploadTime * 5;
      this.increment[call.id] = 45 / (processingTime / 100);

      const interval = setInterval(() => {
        this.uploadProgress[call.id] += this.increment[call.id];

        if (call.progress != 100) {
          if (this.uploadProgress[call.id] >= 90) {
            clearInterval(interval);
            this.uploadProgress[call.id] = 90;

            this.currentLoadingLabel = "Estamos finalizando o envio dos seus anexos..."
          }

          call.progress = this.uploadProgress[call.id];
        }
      }, 100);
    }
  }

  getCurrentUrl() {
    return document.URL
  }

  closeModal(status: boolean = true, cancel: boolean = false, clickCancelBtn: boolean = false) {
    if (status === false || clickCancelBtn) {
      this.fileIds = [];
      this.cancelUpload$.next();
      this.cancelUpload$.complete();
      this.cancelUpload$ = new Subject<void>();
    }

    this.#modal.destroy({
      entityId: this.entityId,
      status,
      requiredValue: this.requiredValue,
      cancel,
      error: this.errorMesage,
      clickCancelBtn,
    });
  }

}
